import React from "react";
import "./index.css";
import Resume from "../../files/Syed Riadh Hossen.pdf";

const Intro = () => {
    const handleDownloadResume = () => {
        let confirm = window.confirm("Download resume?");

        if (confirm) {
            document.getElementById('download_resume').click();
        }
    }

    return (
        <div id='intro_main_div'>
            <div id='intro_div'>
                <code className='fs-6'>Hello, I am</code>
                <h2 id='intro_name'>Syed Riadh Hossen</h2>
                <h2 id='intro_i_do'>I am a Software Engineer</h2>
                <div id='intro_intro' className='lead'>
                    I specialize in crafting web applications using Java and <a
                    href='https://spring.io/projects/spring-boot' target='_blank' rel="noreferrer">Spring
                    Boot</a>.
                </div>
                <button type='button' className='custom_outline_button' onClick={handleDownloadResume}>Download Resume
                </button>
                <a id='download_resume' className='custom_outline_button' href={Resume} target='_self'
                   download='Syed Riadh Hossen'
                   hidden={true}>Download Resume</a>
            </div>
            <div id='open_to_work'>
                <span id='intro_custom_label'>
                    <i className='fa-solid fa-circle'
                       id='intro_circle'/> Open to Full-time opportunities
                </span>
            </div>
        </div>
    );
}

export default Intro;
