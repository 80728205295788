import React from "react";
import "./index.css";

const About = () => {
    return (
        <div id='about_me'>
            <p className='fs-4'><i className='fa-solid fa-user'/> About me</p>
            <hr/>
            <p id='about_me_paragraph'>
                Hello! I am Syed Riadh Hossen, a passionate software engineer hailing from the city of Dhaka,
                Bangladesh.
                <br/>
                <br/>
                Proficient in the art of software development, I specialize in crafting robust and efficient systems from
                scratch, leveraging the power of Java and Spring Boot technologies.
                <br/>
                <br/>
                I excel in both work and personal pursuits with a calm focus on detail and a quick grasp of new skills. 
				I thrive in dynamic settings, adapting and innovating to contribute to success.
                <br/>
            </p>
        </div>
    );
}

export default About;
