import React from "react";
import "./index.css";

const Education = () => {
    return (
        <div id='education_div'>
            <p className='fs-4'><i className='fa-solid fa-graduation-cap'/> Education</p>
            <hr/>
            <div id='education_items'>
                <ul>
                    <li>
                        <div className='education_item'>
                            <p className='fs-4'>Southeast University</p>
                            <p className='fs-5'>Bachelor of Science</p>
                            <p className='fs-5'>Computer Science & Engineering</p>
                            <p>2016-2022</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Education;
