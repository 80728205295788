import React from "react";
import "./index.css";

const Skills = () => {
    return (
        <div id='skills_div'>
            <p className='fs-4'><i className='fa-solid fa-gears'/> Skills</p>
            <hr/>
            <div id='skills_list'>
                <div id='skills_p1'>
                    <code>
                        <ul>
                            <li>Java (8+)</li>
                            <li>Spring Boot</li>
                            <li>MySQL</li>
                            <li>MongoDB</li>
                            <li>Microservices</li>
                            <li>Apache Kafka</li>
                        </ul>
                    </code>
                </div>
                <div id='skills_p2'>
                    <code>
                        <ul>
                            <li>Maven</li>
                            <li>Git</li>
                            <li>Docker</li>
                            <li>JavaScript</li>
                            <li>React.js</li>
                        </ul>
                    </code>
                </div>
            </div>
        </div>
    );
}

export default Skills;
