import React from "react";
import "./index.css";
import SpringReactiveMicroservices from "../../images/spring_microservices.png";
import Crypt from "../../images/crypt_diagram.png";
import InvenTrack from "../../images/inventrack.png";
import Cryptography from "../../images/cryptography.png";
import VLSM from "../../images/vlsm.png";
import JwtAuth from "../../images/jwt_auth.png";
import Weather from "../../images/weather.png";

const Projects = () => {
    return (
        <div id='projects_div'>
            <p className='fs-4'><i className='fa-solid fa-list'/> Projects</p>
            <hr/>
            <div id='projects_list'>
                <div className='project'>
                    <h4>Spring Reactive Microservices</h4>
                    <div className='project_details'>
                        <div className='project_about'>
                            <p>A demonstrative implementation of Reactive microservices using Spring Boot.</p>
                            <div className='project_built_with'>
                                <p>Built with-</p>
                                <code>
                                    <ul>
                                        <li>Java</li>
                                        <li>Spring Boot</li>
                                    </ul>
                                </code>
                            </div>
                            <div className='project_buttons'>
                                <a href='https://github.com/hossensyedriadh/spring-microservices-demo'
                                   target='_blank' rel="noreferrer"><i className='fa-brands fa-github'/></a>
                                {/*<a href='https://inventrack-client.herokuapp.com'
                                   target='_blank' rel="noreferrer"><i
                                    className="fa-solid fa-arrow-up-right-from-square"></i></a>*/}
                                {/*<a href='https://inventrack-restful-service.herokuapp.com/api/swagger-ui/'
                                   target='_blank' rel="noreferrer"><i
                                    className="fa-solid fa-arrow-up-right-from-square"></i></a>*/}
                            </div>
                        </div>
                        <div className='project_snapshot'>
                            <img src={SpringReactiveMicroservices} alt="spring_reactive_microservices" className='project_image'/>
                        </div>
                    </div>
                </div>
                <div className='project'>
                    <h4>Crypt</h4>
                    <div className='project_details'>
                        <div className='project_about'>
                            <p>A credential manager to store and manage passwords, cards, contacts, notes after various layers of encryption.</p>
                            <div className='project_built_with'>
                                <p>Built with-</p>
                                <code>
                                    <ul>
                                        <li>Java</li>
                                        <li>Spring Boot</li>
                                    </ul>
                                </code>
                            </div>
                            <div className='project_buttons'>
                                {/*<a href='https://github.com/hossensyedriadh/inventrack-backend-public'
                                   target='_blank' rel="noreferrer"><i className='fa-brands fa-github'/></a>*/}
                                {/*<a href='https://inventrack-client.herokuapp.com'
                                   target='_blank' rel="noreferrer"><i
                                    className="fa-solid fa-arrow-up-right-from-square"></i></a>*/}
                                {/*<a href='https://inventrack-restful-service.herokuapp.com/api/swagger-ui/'
                                   target='_blank' rel="noreferrer"><i
                                    className="fa-solid fa-arrow-up-right-from-square"></i></a>*/}
                            </div>
                        </div>
                        <div className='project_snapshot'>
                            <img src={Crypt} alt="crypt" className='project_image'/>
                        </div>
                    </div>
                </div>
                <div className='project'>
                    <h4>InvenTrack</h4>
                    <div className='project_details'>
                        <div className='project_about'>
                            <p>A basic inventory management system to manage small business products,
                                sales,
                                purchases,
                                finances etc.</p>
                            <div className='project_built_with'>
                                <p>Built with-</p>
                                <code>
                                    <ul>
                                        <li>Java</li>
                                        <li>Spring Boot</li>
                                        <li>React.js</li>
                                    </ul>
                                </code>
                            </div>
                            <div className='project_buttons'>
                                <a href='https://github.com/hossensyedriadh/inventrack-backend-public'
                                   target='_blank' rel="noreferrer"><i className='fa-brands fa-github'/></a>
                                {/*<a href='https://inventrack-client.herokuapp.com'
                                   target='_blank' rel="noreferrer"><i
                                    className="fa-solid fa-arrow-up-right-from-square"></i></a>*/}
                                {/*<a href='https://inventrack-restful-service.herokuapp.com/api/swagger-ui/'
                                   target='_blank' rel="noreferrer"><i
                                    className="fa-solid fa-arrow-up-right-from-square"></i></a>*/}
                            </div>
                        </div>
                        <div className='project_snapshot'>
                            <img src={InvenTrack} alt="inventrack" className='project_image'/>
                        </div>
                    </div>
                </div>
                <div className='project'>
                    <h4>Cryptography</h4>
                    <div className='project_details'>
                        <div className='project_about'>
                            <p>Implementation of a few encryption, hashing and encoding algorithms to demonstrate
                                basic cryptographic operations.</p>
                            <div className='project_built_with'>
                                <p>Built with-</p>
                                <code>
                                    <ul>
                                        <li>Java</li>
                                        <li>Spring Boot</li>
                                    </ul>
                                </code>
                            </div>
                            <div className='project_buttons'>
                                <a href='https://github.com/hossensyedriadh/cryptography'
                                   target='_blank' rel="noreferrer"><i className='fa-brands fa-github'/></a>
                                {/*<a href='https://cryptography-demo.herokuapp.com'
                                   target='_blank' rel="noreferrer"><i
                                    className="fa-solid fa-arrow-up-right-from-square"></i></a>*/}
                            </div>
                        </div>
                        <div className='project_snapshot'>
                            <img src={Cryptography} alt="cryptography" className='project_image'/>
                        </div>
                    </div>
                </div>
                <div className='project'>
                    <h4>VLSM Calculator</h4>
                    <div className='project_details'>
                        <div className='project_about'>
                            <p>Variable Length Subnet Mask calculator. Calculate subnets by the root network address and
                                hosts in each subnet.</p>
                            <div className='project_built_with'>
                                <p>Built with-</p>
                                <code>
                                    <ul>
                                        <li>React.js</li>
                                    </ul>
                                </code>
                            </div>
                            <div className='project_buttons'>
                                <a href='https://github.com/hossensyedriadh/vlsm'
                                   target='_blank' rel="noreferrer"><i className='fa-brands fa-github'/></a>
                                <a href='https://hossensyedriadh.github.io/vlsm'
                                   target='_blank' rel="noreferrer"><i
                                    className="fa-solid fa-arrow-up-right-from-square"></i></a>
                            </div>
                        </div>
                        <div className='project_snapshot'>
                            <img src={VLSM} alt="vlsm" className='project_image'/>
                        </div>
                    </div>
                </div>
                <div className='project'>
                    <h4>JWT Authentication</h4>
                    <div className='project_details'>
                        <div className='project_about'>
                            <p>A JWT Authentication implementation. The implementation has been documented using Swagger
                                UI.</p>
                            <div className='project_built_with'>
                                <p>Built with-</p>
                                <code>
                                    <ul>
                                        <li>Java</li>
                                        <li>Spring Boot</li>
                                    </ul>
                                </code>
                            </div>
                            <div className='project_buttons'>
                                <a href='https://github.com/hossensyedriadh/spring-boot-jwt-authentication'
                                   target='_blank' rel="noreferrer"><i className='fa-brands fa-github'/></a>
                                {/*<a href='https://spring-boot-jwt-authentication.herokuapp.com/api/swagger-ui/'
                                   target='_blank' rel="noreferrer"><i
                                    className="fa-solid fa-arrow-up-right-from-square"></i></a>*/}
                            </div>
                        </div>
                        <div className='project_snapshot'>
                            <img src={JwtAuth} alt="jwt_auth" className='project_image'/>
                        </div>
                    </div>
                </div>
                <div className='project'>
                    <h4>Weather</h4>
                    <div className='project_details'>
                        <div className='project_about'>
                            <p>A basic weather reporting application</p>
                            <div className='project_built_with'>
                                <p>Built with-</p>
                                <code>
                                    <ul>
                                        <li>React.js</li>
                                        <li>OpenWeatherMap API</li>
                                        <li>OpenCageData API</li>
                                    </ul>
                                </code>
                            </div>
                            <div className='project_buttons'>
                                {/*<a href='https://github.com/hossensyedriadh/weather'
                                   target='_blank' rel="noreferrer"><i className='fa-brands fa-github'/></a>*/}
                                <a href='https://hossensyedriadh.github.io/weather'
                                   target='_blank' rel="noreferrer"><i
                                    className="fa-solid fa-arrow-up-right-from-square"></i></a>
                            </div>
                        </div>
                        <div className='project_snapshot'>
                            <img src={Weather} alt="weather" className='project_image'/>
                        </div>
                    </div>
                </div>
                <div>
                    Other projects can be found in my <a
                    href='https://github.com/hossensyedriadh?tab=repositories' target='_blank'
                    rel="noreferrer">GitHub</a>
                </div>
            </div>
        </div>
    );
}

export default Projects;
