import React, {useRef, useState} from "react";
import "./index.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
    const form = useRef();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleContactFormSubmit = async (e) => {
        e.preventDefault();

        emailjs.sendForm("service_ggbhfp3", "template_ivaknyl", form.current, "sDM2czMLSh5wfXY85")
            .then(() => {
                alert("Message sent!");

                setName("");
                setEmail("");
                setMessage("");
            }, () => {
                alert("Failed to send message");
            });
    }

    const handleNameChange = (e) => {
        setName(String(e.target.value));
    }

    const handleEmailChange = (e) => {
        setEmail(String(e.target.value));
    }

    const handleMessageChange = (e) => {
        setMessage(String(e.target.value));
    }

    return (
        <div id='contact_div'>
            <p className='fs-4'><i className='fa-solid fa-message'/> Contact</p>
            <hr/>
            <section id='form_div'>
                <form ref={form} onSubmit={handleContactFormSubmit}>
                    <div className='mb-3'>
                        <label htmlFor='name' className='form-label'>Your name</label>
                        <input type='text' className='form-control' id='name' name='name' value={name}
                               onChange={handleNameChange} required={true}/>
                    </div>
                    <br/>
                    <div className='mb-3'>
                        <label htmlFor='email' className='form-label'>Your email address</label>
                        <input type='email' className='form-control' id='email' name='email' value={email}
                               onChange={handleEmailChange} required={true}/>
                    </div>
                    <br/>
                    <div className='mb-3'>
                        <label htmlFor='message' className='form-label'>Message</label>
                        <textarea className='form-control' id='message' rows={6} name='message' value={message}
                                  onChange={handleMessageChange} required={true}></textarea>
                    </div>
                    <br/>
                    <div className='mb-3'>
                        <button type='submit' className='btn btn btn-primary'>
                            <i className='fa-solid fa-paper-plane'/> Send message
                        </button>
                    </div>
                </form>
            </section>
        </div>
    );
}

export default Contact;
