import React from "react";
import "./index.css";
import ScalableJavaMicroservices from "../../images/scalable_java_microservices.jpeg";
import AwsS3Basics from "../../images/aws_s3.jpeg";
import AwsVPC from "../../images/create_vpc_using_aws.jpeg";

const Accomplishments = () => {
    return (
        <div id='accomplishments_div'>
            <p className='fs-4'><i className='fa-solid fa-certificate'/> Accomplishments</p>
            <hr/>
            <div id='accomplishment_items'>
                <ul>
                    <li>
                        <div className='accomplishment_item'>
                            <p className='fs-5'>Scalable Java Microservices using Spring Boot and Spring Cloud</p>
                            <img src={ScalableJavaMicroservices} alt={"coursera_accomplishment_scalable_java_microservices"} width="200px"/>
                            <p className='fs-6'><a href="https://coursera.org/share/87c1db669f8219afdd0814c1f9f1c137"
                                                   rel="noreferrer" target="_blank">Coursera</a></p>
                            <p>February, 2021</p>
                        </div>
                    </li>
                    <br/>
                    <li>
                        <div className='accomplishment_item'>
                            <p className='fs-5'>AWS S3 Basics</p>
                            <img src={AwsS3Basics} alt={"coursera_accomplishment_aws_s3_basics"} width="200px"/>
                            <p className='fs-6'><a href="https://coursera.org/share/9176261a834db33060ce0042d984be88" rel="noreferrer"
                            target="_blank">Coursera</a></p>
                            <p>December, 2022</p>
                        </div>
                    </li>
                    <br/>
                    <li>
                        <div className='accomplishment_item'>
                            <p className='fs-5'>Virtual Private Cloud in AWS</p>
                            <img src={AwsVPC} alt={"coursera_accomplishment_aws_vpc"} width="200px"/>
                            <p className='fs-6'><a href="https://coursera.org/share/75bb028dbab9a95aa4b6e5209b4ecabe" rel="noreferrer"
                            target="_blank">Coursera</a></p>
                            <p>December, 2022</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Accomplishments;
